import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Hr, Image, Box, Button, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 90px 0",
	"quarkly-title": "menu"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 500 56px/1.2 --fontFamily-sans",
			"text-align": "center",
			"lg-width": "70%",
			"md-width": "100%",
			"color": "--darkL1",
			"children": "Oynamaya hazır mısın?"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"text-align": "center",
			"font": "normal 400 22px/1.5 --fontFamily-sansHelvetica",
			"color": "--darkL1",
			"padding": "0px 90px 0px 90px",
			"lg-padding": "0px 0 0px 0",
			"lg-margin": "0px 0px 50px 0px",
			"children": "Futbol sahanızı bugün PlayFooty ile ayırtın ve en yüksek kalitede oyun deneyiminin tadını çıkarın! Beklemeyin -kursunuzu hemen ayırtın ve olağanüstü bir oyunun tadını çıkarın."
		}
	},
	"hr": {
		"kind": "Hr",
		"props": {
			"min-height": "10px",
			"margin": "0px 0px 50px 0px",
			"border-color": "--color-darkL1",
			"width": "40px",
			"border-width": "2px 0 0 0"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-template-rows": "auto",
			"grid-gap": "0 35px",
			"md-grid-template-columns": "1fr",
			"md-grid-gap": "40px 0",
			"margin": "0px 0px 70px 0px",
			"md-margin": "0px 0px 50px 0px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 0px 30px 0px"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://inkzstar-place.com/img/3.jpg",
			"display": "block",
			"max-width": "100%",
			"margin": "0px 0px 25px 0px",
			"object-fit": "cover",
			"sm-height": "220px",
			"height": "600px",
			"lg-height": "400px",
			"md-width": "100%",
			"md-height": "450px"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"image1": {
		"kind": "Image",
		"props": {
			"src": "https://inkzstar-place.com/img/4.jpg",
			"display": "block",
			"max-width": "100%",
			"margin": "0px 0px 25px 0px",
			"height": "300px",
			"width": "100%",
			"object-fit": "cover",
			"sm-height": "220px",
			"lg-height": "200px",
			"md-height": "350px"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 0px 30px 0px"
		}
	},
	"image2": {
		"kind": "Image",
		"props": {
			"src": "https://inkzstar-place.com/img/5.jpg",
			"display": "block",
			"max-width": "100%",
			"margin": "0px 0px 25px 0px",
			"height": "300px",
			"width": "100%",
			"object-fit": "cover",
			"sm-height": "220px",
			"lg-height": "200px",
			"md-height": "350px"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"image3": {
		"kind": "Image",
		"props": {
			"src": "https://inkzstar-place.com/img/6.jpg",
			"display": "block",
			"max-width": "100%",
			"margin": "0px 0px 25px 0px",
			"height": "300px",
			"width": "100%",
			"object-fit": "cover",
			"sm-height": "220px",
			"lg-height": "200px",
			"md-height": "350px"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"image4": {
		"kind": "Image",
		"props": {
			"src": "https://inkzstar-place.com/img/7.jpg",
			"display": "block",
			"max-width": "100%",
			"margin": "0px 0px 25px 0px",
			"height": "300px",
			"width": "100%",
			"object-fit": "cover",
			"sm-height": "220px",
			"lg-height": "200px",
			"md-height": "350px"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start"
		}
	},
	"box9": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 0px 30px 0px"
		}
	},
	"box10": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"box11": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"background": "--color-primary",
			"font": "--lead",
			"type": "link",
			"text-decoration-line": "initial",
			"href": "/contact"
		}
	}
};

const Photos = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" align-items="center" />
		<Text {...override("text")} />
		<Text {...override("text1")} />
		<Hr {...override("hr")} />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Image {...override("image")} />
				</Box>
			</Box>
			<Box {...override("box4")}>
				<Box {...override("box5")}>
					<Image {...override("image1")} />
				</Box>
				<Box {...override("box6")}>
					<Image {...override("image2")} />
				</Box>
			</Box>
			<Box {...override("box8")}>
				<Box {...override("box10")}>
					<Image {...override("image3")} />
				</Box>
			</Box>
		</Box>
		<Box {...override("box11")}>
			<Button {...override("button")}>
				Şimdi rezervasyon yapın
			</Button>
		</Box>
		{children}
	</Section>;
};

Object.assign(Photos, { ...Section,
	defaultProps,
	overrides
});
export default Photos;